import React, { useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";

import "./App.css";

const api = axios.create({
  baseURL: "https://catalog-generator.herokuapp.com",
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*"
  }
});

function App() {
  const [state, setState] = useState({
    categories: "",
    codes: "",
    order: "alphabetic",
    price: "false",
    outlet: 1,
    type_user: 1
  });

  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  function handleChange({ target: { value, name } }) {
    setState({ ...state, [name]: value });
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    api
      .post("/create-pdf", state, {
        responseType: "blob",
        onDownloadProgress: progressEvent => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        }
      })
      .then(res => {
        console.log(res);
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        setLoading(false);
        setProgress(0);

        saveAs(pdfBlob, "catalogo.pdf");
      });
  }

  return (
    <div
      style={{
        maxWidth: 400,
        margin: "0 auto",
        fontFamily: "Poppins, sans-serif"
      }}
    >
      <img
        src="https://www.goodsbr.com.br/logo.svg"
        style={{ width: 200, display: "block", margin: "20px auto 0" }}
        alt="Logo Goods Br"
      />
      <h1
        style={{
          textAlign: "center"
        }}
      >
        Gerador de Catálogo
      </h1>

      <form onSubmit={handleSubmit}>
        {/* <div>
          <label>
            Categorias
            <br />
            <input
              type="text"
              placeholder="Digite a Categoria"
              value={state.categories}
              onChange={handleChange}
            />
          </label>
        </div> */}

        {/* <div>
          <label>
            Produtos
            <br />
            <input
              type="text"
              placeholder="Digite o Código"
              value={state.codes}
              onChange={handleChange}
            />
          </label>
        </div> */}

        <div className="inputBlock">
          <label>
            Ordenação
            <br />
            <select
              className="input"
              name="order"
              value={state.order}
              onChange={handleChange}
            >
              <option value="alphabetic">Alfabética</option>
              <option value="price">Preço</option>
              <option value="sku">Código</option>
            </select>
          </label>
        </div>

        <div className="inputBlock">
          <label>
            Preço
            <br />
            <select
              className="input"
              name="price"
              value={state.price}
              onChange={handleChange}
            >
              <option value="false">Sem Preço</option>
              <option value="true">Com Preço</option>
            </select>
          </label>
        </div>

        <div className="inputBlock">
          <label>
            Produtos
            <br />
            <select
              className="input"
              name="outlet"
              value={state.outlet}
              onChange={handleChange}
            >
              <option value="1">Todos produtos</option>
              <option value="2">Somente Outlet</option>
              <option value="3">Somente Produtos de Linha/Lançamentos</option>
            </select>
          </label>
        </div>

        <div className="inputBlock">
          <label>
            Tipo de preço
            <br />
            <select
              className="input"
              name="type_user"
              value={state.type_user}
              onChange={handleChange}
            >
              <option value="1">Lojista</option>
              <option value="2">Corporativo</option>
              <option value="3">Varejo</option>
            </select>
          </label>
        </div>

        <button className="button" disabled={loading}>
          {loading
            ? progress > 0
              ? `Baixando: ${progress}%`
              : "Gerando PDF"
            : "Gerar PDF"}
        </button>
      </form>
    </div>
  );
}

export default App;
